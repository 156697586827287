<template>
  <div></div>
</template>

<script>
export default {
    name: 'Resizer',
    data() {
        return {
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth,
            hugeWindowWidth: 1585,
            bigWindowWidth: 1400,
            mediumWindowWidth: 1300,
            littleWindowWidth: 1100,
            microWindowWidth: 900,
            ultraWindowWidth: 700,
            resizeEvent: 'resize',
            megaEllipsis: 'ellipsis-mega',
            ultraEllipsis: 'ellipsis-ultra',
            superEllipsis: 'ellipsis-super',
            strongEllipsis: 'ellipsis-strong',
            mediumEllipsis: 'ellipsis-medium',
            normalEllipsis: 'ellipsis',
        };
    },
    mounted() {
        window.addEventListener(this.resizeEvent, this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener(this.resizeEvent, this.onResize);
    },
    computed: {
        ellipseClassName() {
            switch (true) {
            case this.windowWidth <= this.ultraWindowWidth:
                return this.megaEllipsis;
            case this.windowWidth <= this.microWindowWidth:
                return this.ultraEllipsis;
            case this.windowWidth <= this.littleWindowWidth:
                return this.superEllipsis;
            case this.windowWidth <= this.mediumWindowWidth:
                return this.strongEllipsis;
            case this.windowWidth <= this.bigWindowWidth:
                return this.strongEllipsis;
            case this.windowWidth <= this.hugeWindowWidth:
                return this.normalEllipsis;
            default:
                return '';
            }
        },
    },
    methods: {
        onResize() {
            this.windowHeight = window.innerHeight;
            this.windowWidth = window.innerWidth;
        },
    },
};
</script>
