
export const correct = {
    name: 'Correct',
    item: 'CORRECT'
};
export const wrong = {
    name: 'Wrong',
    item: 'WRONG'
};
export const retry = {
    name: 'Retry',
    item: 'RETRY'
};
const threeChoices = [correct, wrong, retry];
const twoChoices = [correct, wrong];

const transactionUpdateOptions = {
    threeChoices: threeChoices,
    twoChoices: twoChoices,
    retry: retry,
    correct: correct,
    wrong: wrong
};

export default transactionUpdateOptions;
