<script>
import {TwoLevelApprovalStatus} from "@/utils/twoLevelApproval";
import PaymentStatus, {getPaymentStatus} from "@/utils/PaymentStatus";
import ApiUtils from "@/utils/ApiUtils.vue";
import PaymentType, {getPaymentType} from "@/utils/PaymentType";

export default {
    name: "StatusUtils",
    mixins: [ApiUtils],
    methods: {
        doesCompanyHaveTwoLevelApproval(transaction) {
            const {paymentType} = transaction;
            if (paymentType === getPaymentType('PAYOUTFEE').symbol) {
                return false;
            }
            return this.$store.getters
                .doesCompanyHaveTwoLevelApprovalThreshold(transaction.companyId, transaction.reasonNote);
        },
        isTwoLevelApprovalTransaction(transaction) {
            // NOT GOOD
            const {approver, approvedBy, paymentType} = transaction;
            if (paymentType === PaymentType.PAYOUTFEE.symbol) return false;
            // Company can change status for 2 level approval, but some info will be keep there inside the transaction.
            // This cover Edge Case 1 in documentation
            return approver !== null && approvedBy !== null;
        },
        isTwoLevelApprovalFinal(transaction) {
            const twoLevelApprovalStatus = this.getTwoLevelApprovalStatus(transaction);
            return twoLevelApprovalStatus === TwoLevelApprovalStatus.APPROVED;
        },
        isActionNotRequired(transaction) {
            const {status} = transaction;
            const twoLevelApprovalStatus = this.getTwoLevelApprovalStatus(transaction);
            return status === PaymentStatus.TOBE_EXECUTED.symbol
                || twoLevelApprovalStatus === TwoLevelApprovalStatus.NO_ACTION;
        },
        isActionRequired(transaction) {
            const twoLevelApprovalStatus = this.getTwoLevelApprovalStatus(transaction);
            return twoLevelApprovalStatus === TwoLevelApprovalStatus.REFUSED_BY_PAYDAY
                || twoLevelApprovalStatus === TwoLevelApprovalStatus.NEED_APPROVAL
                || twoLevelApprovalStatus === TwoLevelApprovalStatus.REFUSED_BY_PLATFORM
                || twoLevelApprovalStatus === TwoLevelApprovalStatus.APPROVED_BY_PAYDAY
                || twoLevelApprovalStatus === TwoLevelApprovalStatus.APPROVED_BY_PLATFORM;
        },
        getActionText(transaction) {
            let platformName = '';
            const companyName = this.getCompanyName(transaction.companyId);
            if (transaction.approver === null) {
                return '';
            }
            if (transaction.approver === 'ROLE_OWNER') {
                platformName = 'Payday';
            } else {
                platformName = companyName;
            }
            return this.$t('admin.transactions.status-need-approval-platform', {platform: platformName});
        },
        getCorrectStatusName(transaction) {
            const {approvalStatus, status, isCompanyTwoLevelApproval, companyId} = transaction;
            const paymentStatus = getPaymentStatus(status, approvalStatus, isCompanyTwoLevelApproval);
            const companyName = this.getCompanyName(companyId);
            let approver = '';
            switch (approvalStatus) {
            case TwoLevelApprovalStatus.NEED_APPROVAL:
                approver = companyName;
                return this.$t(paymentStatus.name, {platform: approver});
            case TwoLevelApprovalStatus.APPROVED_BY_PLATFORM:
                approver = companyName;
                return this.$t(paymentStatus.name, {platform: approver});
            case TwoLevelApprovalStatus.REFUSED_BY_PLATFORM:
                approver = companyName;
                return this.$t(paymentStatus.name, {platform: approver});
            case TwoLevelApprovalStatus.APPROVED_BY_PAYDAY:
                approver = 'Payday';
                return this.$t(paymentStatus.name, {platform: approver});
            case TwoLevelApprovalStatus.REFUSED_BY_PAYDAY:
                approver = 'Payday';
                return this.$t(paymentStatus.name, {platform: approver});
            default:
                return this.$t(paymentStatus.name);
            }
        },
    }
};
</script>
