<template>
  <div></div>
</template>

<script>
import submittedImage from '@/assets/images/icon-verified-submitted.png';
import paidImage from '@/assets/images/icon-verified-paid.png';
import errorImage from '@/assets/images/icon-error.png';
import infoImage from '@/assets/images/icon-info-square.png';
import colors from '@/utils/ColorsConts';

export default {
    name: 'TransactionStatusUtils',
    data() {
        return {
            paymentStatus: {
                STORED: {
                    name: this.$t('admin.transactions.status-submitted'),
                    img: submittedImage,
                    color: colors.lightGreen,
                    className: 'payment-status-submitted-container',
                    code: 'STORED'
                },
                PAYC_STORED: {
                    name: this.$t('admin.transactions.status-submitted'),
                    img: submittedImage,
                    color: colors.lightGreen,
                    className: 'payment-status-submitted-container',
                    code: 'PAYC_STORED'
                },
                PAY_STORED: {
                    name: this.$t('admin.transactions.status-submitted'),
                    img: submittedImage,
                    color: colors.lightGreen,
                    className: 'payment-status-submitted-container',
                    code: 'PAY_STORED',
                },
                PAYC_COMPLETED: {
                    name: this.$t('admin.transactions.status-paid'),
                    img: paidImage,
                    color: colors.green,
                    className: 'payment-status-paid-container',
                    code: 'PAYC_COMPLETED'
                },
                PAID: {
                    name: this.$t('admin.transactions.status-paid'),
                    img: paidImage,
                    color: colors.green,
                    className: 'payment-status-paid-container',
                    code: 'PAID'
                },
                PAYOUTAUTO: {
                    name: this.$t('admin.transactions.status-paid'),
                    img: paidImage,
                    color: colors.green,
                    className: 'payment-status-paid-container',
                    code: 'PAYOUTAUTO'
                },
                EXECUTED: {
                    name: this.$t('admin.transactions.status-paid'),
                    img: paidImage,
                    color: colors.green,
                    className: 'payment-status-paid-container',
                    code: 'EXECUTED'
                },
                PAY_COMPLETED: {
                    name: this.$t('admin.transactions.status-paid'),
                    img: paidImage,
                    color: colors.green,
                    className: 'payment-status-paid-container',
                    code: 'PAY_COMPLETED'
                },
                PAYC_ADJHOUR: {
                    name: this.$t('admin.transactions.status-hour-adj'),
                    img: submittedImage,
                    color: colors.blue,
                    className: 'payment-status-submitted-container',
                    code: 'PAYC_ADJHOUR'
                },
                PAYC_ADJNET: {
                    name: this.$t('admin.transactions.status-net-adj'),
                    img: submittedImage,
                    color: colors.blue,
                    className: 'payment-status-submitted-container',
                    code: 'PAYC_ADJNET'
                },
                TOBE_EXECUTED: {
                    name: this.$t('admin.transactions.status-to-be-exec'),
                    img: infoImage,
                    color: colors.lightBlue,
                    className: 'payment-status-toconfirm-container',
                    code: 'TOBE_EXECUTED'
                },
                EXECUTING: {
                    name: this.$t('admin.transactions.status-executing'),
                    img: paidImage,
                    color: colors.blue,
                    className: 'payment-status-toconfirm-container',
                    code: 'EXECUTING'
                },
                REJECTED: {
                    name: this.$t('admin.transactions.status-rejected'),
                    img: errorImage,
                    color: colors.red,
                    className: 'payment-status-failed-container',
                    code: 'REJECTED'
                },
                INPROGRESS: {
                    name: this.$t('admin.transactions.status-in-progress'),
                    img: infoImage,
                    color: colors.blue,
                    className: 'payment-status-toconfirm-container',
                    code: 'INPROGRESS'
                },
                TOCONFIRM: {
                    name: this.$t('admin.transactions.status-need-approval'),
                    img: infoImage,
                    color: colors.lightBlue,
                    className: 'payment-status-toconfirm-container',
                    code: 'TOCONFIRM'
                },
                REFUSED: {
                    name: this.$t('admin.transactions.status-refused'),
                    img: errorImage,
                    color: colors.red,
                    className: 'payment-status-failed-container',
                    code: 'REFUSED'
                },
                FAILED: {
                    name: this.$t('admin.transactions.status-failed'),
                    img: errorImage,
                    color: colors.red,
                    className: 'payment-status-failed-container',
                    code: 'FAILED'
                },
                SCHEDULED: {
                    name: this.$t('admin.transactions.scheduled'),
                    img: infoImage,
                    color: colors.lightBlue,
                    className: 'payment-status-toconfirm-container',
                    code: 'SCHEDULED'
                },
                ON_HOLD: {
                    name: this.$t('admin.transactions.status-on-hold'),
                    img: infoImage,
                    color: colors.lightBlue,
                    className: 'payment-status-toconfirm-container',
                    code: 'ON_HOLD'    
                },
            },
            paymentTypes: {
                PAYC_ADJHOUR: this.$t('admin.transactions.type-hour'),
                PAYC_ADJNET: this.$t('admin.transactions.type-settlement'),
                PAYOUT: this.$t('admin.transactions.type-debit-manual'),
                PAYOUTAUTO: this.$t('admin.transactions.type-debit-auto'),
                PAYOUTFEE: this.$t('admin.transactions.platform-fee'),
                PAYOUTSET: this.$t('admin.transactions.payout-set'),
                PIS: this.$t('admin.transactions.type-direct'),
                WAL: this.$t('admin.transactions.type-credit'),
                WALC: this.$t('admin.transactions.type-credit'),
                WALCADJ: this.$t('admin.transactions.status-net-adj'),
            },
        };
    },
    methods: {
        getStatus(type) {
            return this.paymentStatus[type];
        },
        getStatusName(type) {
            return this.getStatus(type) ? this.getStatus(type).name : 'Unknown Status';
        },
        getPaymentType(type) {
            return this.paymentTypes[type];
        },
        isUnknown(status) {
            return status === '';
        },
        isSubmitted(status, type) {
            return status === 'PAYC_STORED' ||
          status === 'PAY_STORED' ||
          status === 'WALCADJ' ||
          type === 'WALCADJ';
        },
        isFailed(status, type) {
            return status === 'FAILED' ||
          status === 'REJECTED' ||
          status === 'REFUSED' ||
          type === 'FAILED' ||
          type === 'REFUSED' ||
          type === 'REJECTED';
        },
        isPaid(status, type) {
            return status === 'EXECUTED' ||
          status === 'PAY_COMPLETED' ||
          status === 'PAYC_COMPLETED' ||
          status === 'EXECUTING' ||
          status === 'PAID' ||
          type === 'PAID' ||
          type === 'PAYOUTAUTO' ||
          type === 'PAYOUT' ||
          type === 'EXECUTING' ||
          type === 'EXECUTED' ||
          type === 'PAYC_COMPLETED';
        },
        isNeedToApproval(status, type) {
            return status === 'TOCONFIRM' || type === 'TOCONFIRM';
        },
        isPending(status, type) {
            return status === 'INPROGRESS' ||
          status === 'TOBE_EXECUTED' ||
          type === 'INPROGRESS' ||
          type === 'TOBE_EXECUTED';
        },
        isStatus(status) {
            return status === 'EXECUTED' ||
          status === 'FAILED' ||
          status === 'REFUSED' ||
          status === 'TOBE_EXECUTED' ||
          status === 'TOCONFIRM' ||
          status === 'PAY_COMPLETED' ||
          status === 'PAYC_COMPLETED' ||
          status === 'PAY_STORED' ||
          status === 'PAYC_STORED' ||
          status === 'INPROGRESS' ||
          status === 'REJECTED' ||
          status === 'PAYOUTFEE' ||
          status === 'PAYOUTAUTO' ||
          status === 'PAYOUT';
        },
        getClassNameForType(status, type) {
            if (this.isSubmitted(status, type)) {
                return 'payment-status-submitted-container';
            }
            if (this.isFailed(status, type)) {
                return 'payment-status-failed-container';
            }
            if (this.isNeedToApproval(status, type) || this.isPending(status, type)) {
                return 'payment-status-toconfirm-container';
            }
            if (this.isPaid(status, type)) {
                return 'payment-status-paid-container';
            }
            return 'payment-status-submitted-container';
        },
    }
};
</script>
